import { default as arrangementMXaQI49gwlMeta } from "/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/arrangement.vue?macro=true";
import { default as bedanktbi30DuGC5mMeta } from "/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/bedankt.vue?macro=true";
import { default as datumHVOEKx14SlMeta } from "/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/datum.vue?macro=true";
import { default as donerenfqbW7G8RChMeta } from "/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/doneren.vue?macro=true";
import { default as indexCCHyjnspauMeta } from "/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/index.vue?macro=true";
import { default as overzichtmDnoxPegnyMeta } from "/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/overzicht.vue?macro=true";
export default [
  {
    name: arrangementMXaQI49gwlMeta?.name ?? "arrangement",
    path: arrangementMXaQI49gwlMeta?.path ?? "/arrangement",
    meta: arrangementMXaQI49gwlMeta || {},
    alias: arrangementMXaQI49gwlMeta?.alias || [],
    redirect: arrangementMXaQI49gwlMeta?.redirect || undefined,
    component: () => import("/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/arrangement.vue").then(m => m.default || m)
  },
  {
    name: bedanktbi30DuGC5mMeta?.name ?? "bedankt",
    path: bedanktbi30DuGC5mMeta?.path ?? "/bedankt",
    meta: bedanktbi30DuGC5mMeta || {},
    alias: bedanktbi30DuGC5mMeta?.alias || [],
    redirect: bedanktbi30DuGC5mMeta?.redirect || undefined,
    component: () => import("/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: datumHVOEKx14SlMeta?.name ?? "datum",
    path: datumHVOEKx14SlMeta?.path ?? "/datum",
    meta: datumHVOEKx14SlMeta || {},
    alias: datumHVOEKx14SlMeta?.alias || [],
    redirect: datumHVOEKx14SlMeta?.redirect || undefined,
    component: () => import("/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/datum.vue").then(m => m.default || m)
  },
  {
    name: donerenfqbW7G8RChMeta?.name ?? "doneren",
    path: donerenfqbW7G8RChMeta?.path ?? "/doneren",
    meta: donerenfqbW7G8RChMeta || {},
    alias: donerenfqbW7G8RChMeta?.alias || [],
    redirect: donerenfqbW7G8RChMeta?.redirect || undefined,
    component: () => import("/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/doneren.vue").then(m => m.default || m)
  },
  {
    name: indexCCHyjnspauMeta?.name ?? "index",
    path: indexCCHyjnspauMeta?.path ?? "/",
    meta: indexCCHyjnspauMeta || {},
    alias: indexCCHyjnspauMeta?.alias || [],
    redirect: indexCCHyjnspauMeta?.redirect || undefined,
    component: () => import("/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: overzichtmDnoxPegnyMeta?.name ?? "overzicht",
    path: overzichtmDnoxPegnyMeta?.path ?? "/overzicht",
    meta: overzichtmDnoxPegnyMeta || {},
    alias: overzichtmDnoxPegnyMeta?.alias || [],
    redirect: overzichtmDnoxPegnyMeta?.redirect || undefined,
    component: () => import("/home/tickets-r2ncy/tickets.dev2.on24.nl/client/pages/overzicht.vue").then(m => m.default || m)
  }
]